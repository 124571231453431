import React from "react";
import { ErrorPage } from "./errorPage";

export class ErrorBoundaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />
        }

        return this.props.children;
    }
}