import { useEffect, useState } from "react";

export const useDialog = () => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (content !== null) {
            openDialog();
        }
    }, [content])
    
    const closeDialog = () => {
        setOpen(false);
    }

    const openDialog = () => {
        setOpen(true)
    }

    return {
        open,
        openDialog,
        closeDialog,
        content,
        setContent
    }
}