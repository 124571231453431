export const withOpacity = (color, opacity) => {
    let opc = opacity * 256;
    if (opc > 256) {
        opc = 256;
    }
    if (opc < 0) {
        opc = 0;
    }
    const newColor = `${color}${Math.floor(opc).toString(16)}`;
    return newColor;
};

export const multiplyBorderRadiusWithTheme = (startingValue) => {
    return (theme) => startingValue * theme.borderRadius + 'px';
};

export const homePageContainerWidths = (theme) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        width: '70%',
    },
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%',
    },
});

export const _baseTheme = {
    borderRadius: 0.8,
    font: 'nunito',
    palette: {
        primary: { main: '#e7bb41' },
        secondary: { main: '#44bba4' },
        background: { main: '#e7e5df' },
        grey50: { main: '#393e41' },
        grey150: { main: '#d3d0cb' },
        grey250: { main: '#e7e5df' },
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1800,
        },
    },
};
