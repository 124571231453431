// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
    collection,
    getFirestore,
    getDocs,
    getDoc,
    doc,
    query,
    where,
    onSnapshot,
} from 'firebase/firestore';
import { useParams } from 'react-router';
import { TenantModel } from '../models/tenantModel';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

export const useFirebase = () => {
    return {
        // Initialize Firebase
        init: () => initializeApp(firebaseConfig),
    };
};

export const useFirestore = () => {
    const db = getFirestore();
    const { tenantId } = useParams();

    const getObject = async (collectionName, id) => {
        return await getDoc(doc(db, collectionName, id));
    };

    const getTenantObject = async () => {
        return await getDoc(doc(db, `${TenantModel.collectionName}`, tenantId));
    };

    const getSubscriptionFromTenantDoc = async (callback) => {
        return onSnapshot(
            doc(db, `${TenantModel.collectionName}`, tenantId),
            (snapshot) => {
                callback(snapshot);
            }
        );
    };

    const getSubscriptionFromTenantCollectionObject = (
        collectionName,
        id,
        callback
    ) => {
        return onSnapshot(
            doc(
                db,
                `${TenantModel.collectionName}/${tenantId}/${collectionName}`,
                id
            ),
            (snapshot) => {
                callback(snapshot);
            }
        );
    };

    const getTenantCollectionObject = async (collectionName, id) => {
        return await getDoc(
            doc(
                db,
                `${TenantModel.collectionName}/${tenantId}/${collectionName}`,
                id
            )
        );
    };

    const listTenantObjects = async (collectionName) => {
        return await getDocs(
            collection(
                db,
                `${TenantModel.collectionName}/${tenantId}/${collectionName}`
            )
        );
    };

    const listTenantObjectsWithFilter = async (
        collectionName,
        filterList = []
    ) => {
        return await getDocs(
            query(
                collection(
                    db,
                    `${TenantModel.collectionName}/${tenantId}/${collectionName}`
                ),
                ...filterList.map((el) => where(el[0], el[1], el[2]))
            )
        );
    };

    return {
        getObject,
        getTenantObject,
        getTenantCollectionObject,
        listTenantObjects,
        listTenantObjectsWithFilter,
        getSubscriptionFromTenantDoc,
        getSubscriptionFromTenantCollectionObject,
    };
};
