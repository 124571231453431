import { Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React, { useContext, useEffect, useState } from 'react';
import { LoadPage } from '../../components/LoadPage';
import {
    MenuContext,
    TenantContext,
} from '../../contexts/providers/appContexts';
import { CategoryPanel } from './categoryPanel';
import { ItemDetails } from './itemDetails';
import { ScrollingFixedContainer } from '../../components/scrollingFixedPage';
import { useNavigate, useParams } from 'react-router-dom';
import {
    homePageContainerWidths,
    multiplyBorderRadiusWithTheme,
} from '../../utils/theme';
import { Slide, ToastContainer } from 'react-toastify';

import '../../styles/toasts/main.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SwiperSection = React.memo(
    ({ onSwiper, categories, onSelectItem, onIndexChange }) => (
        <Swiper
            onSwiper={onSwiper}
            speed={150}
            autoHeight
            onActiveIndexChange={onIndexChange}
        >
            {categories.map((category) => (
                <SwiperSlide key={category.name}>
                    <CategoryPanel
                        category={category}
                        onSelectItem={onSelectItem}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    ),
    (prevProps, nextProps) => prevProps.categories === nextProps.categories
);

const MemoizedToastContainer = React.memo(() => (
    <ToastContainer
        position="top-left"
        hideProgressBar={false}
        closeOnClick
        draggable
        transition={Slide}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
    />
));

export const HomePage = () => {
    const menuService = useContext(MenuContext);
    const tenantService = useContext(TenantContext);
    const [categoryIndex, setCategoryIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showItem, setShowItem] = useState(false);
    const [YscrollValue, setYScrollValue] = useState(0);
    const [swiper, setSwiper] = useState(null);
    const navigate = useNavigate();
    const { tenantId, mode } = useParams();

    // 20vh - 50 (barra  menù) , 48px  = h tabs
    const scrollBreakPoint = 0.2 * window.innerHeight - 50;

    useEffect(() => {
        const handleScroll = (e) => {
            setYScrollValue(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (swiper !== null) {
            swiper.slideReset(200);
        }
    }, [menuService.menu, swiper])

    return menuService.menu ? (
        <>
            <Box position="fixed" height="50px" zIndex="10" width="100%">
                <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                >
                    <Typography
                        variant="h5"
                        marginLeft="20px"
                        sx={{
                            opacity:
                                YscrollValue < scrollBreakPoint
                                    ? YscrollValue / scrollBreakPoint
                                    : 1,
                            color: 'grey250.main',
                        }}
                    >
                        Menu
                    </Typography>
                    <IconButton
                        onClick={() => {
                            navigate(`/${tenantId}/${mode}/cart`);
                        }}
                        sx={{
                            marginRight: '15px',
                            backgroundColor: 'primary.main',
                            borderRadius: '20px',
                            padding: '5px 10px',
                            '&:hover': { backgroundColor: 'primary.main' },
                        }}
                    >
                        <ShoppingCartIcon
                            sx={{ color: 'grey250.main', marginLeft: '5px' }}
                        />
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="grey250.main"
                            marginLeft="5px"
                            width="30px"
                            textAlign="center"
                        >
                            {menuService.getTotalCartItems()}
                        </Typography>
                    </IconButton>
                </Stack>
            </Box>
            <Box
                position="relative"
                sx={{ width: '100%', height: 'calc(20vh + 48px)' }}
            >
                <img
                    src={tenantService.tenantDetails.coverImage}
                    alt="Cover"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: -1,
                        position: 'absolute',
                        top: 0,
                    }}
                />
                <Box
                    position="absolute"
                    sx={{
                        height:
                            YscrollValue >= scrollBreakPoint ? '98px' : '100%',
                        backgroundColor: (theme) => theme.palette.primary.main,
                        width: '100%',
                        opacity:
                            YscrollValue < scrollBreakPoint
                                ? YscrollValue / scrollBreakPoint
                                : 1,
                        position:
                            YscrollValue >= scrollBreakPoint
                                ? 'fixed'
                                : 'static',
                        top: 0,
                    }}
                    zIndex="2"
                ></Box>
            </Box>
            <Box
                sx={(theme) => ({
                    ...homePageContainerWidths(theme),
                })}
                margin="0 auto"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    sx={{
                        backgroundColor: 'background.main',
                        top: '50px',
                        borderTopLeftRadius: multiplyBorderRadiusWithTheme(40),
                        borderTopRightRadius: multiplyBorderRadiusWithTheme(40),
                        marginTop: '-48px',
                        position: 'sticky',
                    }}
                    zIndex="4"
                >
                    <Tabs
                        sx={{
                            backgroundColor: 'background.main',
                            borderTopLeftRadius:
                                multiplyBorderRadiusWithTheme(40),
                            borderTopRightRadius:
                                multiplyBorderRadiusWithTheme(40),
                            zIndex: 3,
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                        variant="scrollable"
                        value={categoryIndex}
                        onChange={(e, newValue) => {
                            setCategoryIndex(newValue);
                            if (swiper) {
                                swiper.slideTo(newValue);
                            }
                        }}
                    >
                        {menuService.menu.categories.map((category) => (
                            <Tab
                                disableRipple
                                sx={{ color: 'grey150.main' }}
                                label={category.name}
                                key={category.name}
                            />
                        ))}
                    </Tabs>
                </Box>
                <SwiperSection
                    onSwiper={setSwiper}
                    onIndexChange={(swiper) => {
                        setCategoryIndex(swiper.activeIndex);
                    }}
                    categories={menuService.menu.categories}
                    onSelectItem={(item) => {
                        if (
                            item.description ||
                            item.ingredients.length > 0 ||
                            item.allergens.length > 0
                        ) {
                            setSelectedItem(item);
                            setShowItem(true);
                        }
                    }}
                />
            </Box>
            <ScrollingFixedContainer
                onClose={() => {
                    setShowItem(false);
                }}
                isOpen={showItem}
            >
                <ItemDetails
                    item={selectedItem}
                    onClose={() => {
                        setShowItem(false);
                    }}
                />
            </ScrollingFixedContainer>
            <MemoizedToastContainer />
        </>
    ) : (
        <LoadPage />
    );
};
