import { useFirestore } from './firebase';
import { TenantModel } from '../models/tenantModel';
import { useState } from 'react';

export const useTenant = () => {
    const db = useFirestore();
    const [tenantDetails, setTenantDetails] = useState(null);

    const getTenantDetails = async () => {
        try {
            const tenant = TenantModel.fromSnapshot(
                (await db.getTenantObject()).data()
            );
            setTenantDetails(tenant);
            return tenant;
        } catch (e) {
            setTenantDetails(() => {
                throw e;
            });
        }
    };

    return {
        getTenantDetails,
        tenantDetails,
    };
};
