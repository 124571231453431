import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import AppProvider from './contexts/providers/appProvider';
import { HomePage, CartPage } from './pages/index';
import { ErrorBoundaryPage } from './pages/index';

const RouteNames = {
    HOME: '/:tenantId/:mode/',
    CART: '/:tenantId/:mode/cart',
};

export const AppRoutes = () => {
    const location = useLocation();

    return (
        <ErrorBoundaryPage>
            <Routes location={location}>
                <Route element={<AppProvider />}>
                    <Route path={RouteNames.HOME} element={<HomePage />} />
                    <Route path={RouteNames.CART} element={<CartPage />} />
                </Route>
            </Routes>
        </ErrorBoundaryPage>
    );
};
