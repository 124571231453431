import {
    Button,
    ButtonGroup,
    Container,
    Stack,
    TextField,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { FixedBottomBar } from '../../components/fixedBottomBar';
import { ScrollingFixedPage } from '../../components/scrollingFixedPage';
import { TenantContext, MenuContext } from '../../contexts/providers/appContexts';
import moment from 'moment';

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

const OrderDetailTextField = ({
    label,
    type,
    placeholder,
    controller,
    onChange,
    formatter,
}) => {
    const isValid = controller.init || controller.validator(controller.value);

    return (
        <TextField
            error={!isValid}
            helperText={isValid ? null : controller.errorMessage}
            sx={{ marginBottom: '20px' }}
            value={formatter ? formatter(controller.value) : controller.value}
            onChange={onChange}
            label={label}
            type={type}
            InputLabelProps={{
                shrink: true,
            }}
            placeholder={placeholder}
        />
    );
};

export const SendOrderScrollingPage = ({ onClose, ...rest }) => {
    const menuService = useContext(MenuContext);
    const tenantService = useContext(TenantContext);
    const [isDelivery, setDelivery] = useState(false);
    const [formControl, setFormControl] = useState({
        name: {
            value: '',
            validator: (value) => value !== '',
            errorMessage: 'Il nome non può essere vuoto!',
            init: true,
        },
        address: {
            value: '',
            validator: (value) => value !== '',
            errorMessage: "L'indirizzo non può essere vuoto!",
            init: true,
        },
        date: {
            value: new Date(Date.now()),
            validator: (value) => isValidDate(value),
            errorMessage: 'La data non è nel formato corretto!',
            init: true,
        },
        time: {
            value: new Date(Date.now()),
            validator: (value) => isValidDate(value),
            errorMessage: "L'ora non è nel formato corretto!",
            init: true,
        },
    });

    const validateAllFields = () => {
        let formsToValidate = Object.keys(formControl);
        if (!isDelivery)
            formsToValidate = formsToValidate.filter((el) => el !== 'address');
        return formsToValidate.every((k) =>
            formControl[k].validator(formControl[k].value)
        );
    };

    const updateController = (name, elaborateValue) => {
        return (e) => {
            setFormControl((prev) => {
                const newValue = { ...prev };
                newValue[name].value = elaborateValue
                    ? elaborateValue(e.target.value)
                    : e.target.value;
                if (newValue[name].init) newValue[name].init = false;
                return newValue;
            });
        };
    };

    const orderMessage = `Buongiorno, vorrei fare un ordine ${
        isDelivery ? 'con consegna' : "d'asporto"
    } il giorno ${moment(formControl.date.value).format(
        'DD/MM/YYYY'
    )} alle ${moment(formControl.time.value).format(
        'HH:mm'
    )}. Questo è ciò che vorrei ordinare:\n${menuService.cart
        .map((cartItem) => `${cartItem.quantity}x ${cartItem.item.name}`)
        .join('\n')}\n${
        isDelivery
            ? `L'indirizzo per la consegna è ${formControl.address.value}.\n`
            : ''
    }L'ordine è a nome di ${formControl.name.value}`;

    return (
        <ScrollingFixedPage
            title={tenantService.tenantDetails.hasDelivery ? "Invio ordine" : "Invio ordine d'asporto"}
            onClose={onClose}
            containerPaddingBottom="12vh"
            fixedBottomBar={
                <FixedBottomBar height="12vh">
                    <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            sx={{
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(0,0,0,0.15)',
                                },
                            }}
                            disabled={!validateAllFields()}
                            href={`https://wa.me/39${
                                tenantService.tenantDetails.waNumber
                            }?text=${encodeURIComponent(orderMessage)}`}
                            target="_blank"
                            variant="white-btn"
                        >
                            Invia Ordine
                        </Button>
                    </Stack>
                </FixedBottomBar>
            }
            height="auto"
            {...rest}
        >
            <Container>
                <Stack>
                    {tenantService.tenantDetails.hasDelivery && (<ButtonGroup
                        disableElevation
                        sx={{ margin: '10px 0 20px 0' }}
                        variant="contained"
                    >
                        <Button
                            sx={{
                                flexGrow: 1,
                            }}
                            onClick={() => {
                                setDelivery(false);
                            }}
                            variant={
                                !isDelivery ? 'contained-primary' : 'contained'
                            }
                        >
                            Asporto
                        </Button>
                        <Button
                            sx={{
                                flexGrow: 1,
                            }}
                            onClick={() => {
                                setDelivery(true);
                            }}
                            variant={
                                isDelivery ? 'contained-primary' : 'contained'
                            }
                        >
                            Consegna
                        </Button>
                    </ButtonGroup>)}
                    <OrderDetailTextField
                        label="Nome"
                        type="text"
                        placeholder="Inserisci il tuo nome"
                        onChange={updateController('name')}
                        controller={formControl.name}
                    />
                    <OrderDetailTextField
                        label="Data ordine"
                        type="date"
                        onChange={updateController('date', (value) =>
                            moment(value, 'YYYY-MM-DD').toDate()
                        )}
                        controller={formControl.date}
                        formatter={(date) =>
                            moment(date).local().format('YYYY-MM-DD')
                        }
                    />
                    <OrderDetailTextField
                        label="Ora ordine"
                        type="time"
                        onChange={updateController('time', (value) =>
                            moment(value, 'HH:mm').toDate()
                        )}
                        controller={formControl.time}
                        formatter={(time) => moment(time).format('HH:mm')}
                    />
                    {isDelivery && (
                        <OrderDetailTextField
                            label="Indirizzo"
                            type="text"
                            placeholder="Inserisci l'indirizzo per la consegna"
                            onChange={updateController('address')}
                            controller={formControl.address}
                        />
                    )}
                </Stack>
            </Container>
        </ScrollingFixedPage>
    );
};
