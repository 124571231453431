import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const LoadPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
