import React, { useContext, useState } from 'react';
import { LoadPage } from '../../components/LoadPage';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FixedPriceAndButtonBottomBar } from '../../components/fixedBottomBar';
import { CartItemHandler } from './cartItemHandler';
import { ScrollingFixedContainer } from '../../components/scrollingFixedPage';
import { QRCodeViewer } from './qrCodeViewer';
import emptyCart from '../../assets/empty_cart.png';
import { homePageContainerWidths } from '../../utils/theme';
import { ContactScrollingPage } from './contactScrollingPage';
import { ContactScrollingPageOnlyCalls } from './contactScrollingPageOnlyCalls';
import { TenantContext, MenuContext, DialogContext } from '../../contexts/providers/appContexts';
import { modeToMenuKey } from '../../contexts/providers/appProvider';
import { useFirestore } from '../../contexts/services/firebase';

export const CartPage = () => {
    const menuService = useContext(MenuContext);
    const { setContent } = useContext(DialogContext)
    const db = useFirestore();
    const { tenantId, mode } = useParams();
    const navigate = useNavigate();
    const [isOpenOrderPage, setOpenOrderpage] = useState(false);
    const tenantService = useContext(TenantContext);

    return menuService.menu ? (
        <>
            <Box
                position="fixed"
                height="50px"
                zIndex="10"
                top="0"
                sx={{ backgroundColor: 'background.main' }}
            >
                <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexDirection="row"
                    width="100vw"
                >
                    <IconButton
                        sx={{ marginLeft: '5px', color: 'grey50.main' }}
                        onClick={() => {
                            navigate(`/${tenantId}/${mode}`);
                        }}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Resoconto del carrello
                    </Typography>
                </Stack>
            </Box>
            <Box
                sx={(theme) => ({
                    backgroundColor: 'background.main',
                    ...homePageContainerWidths(theme),
                    margin: '0 auto',
                })}
            >
                <Container
                    sx={{
                        marginTop: '50px',
                        paddingBottom: '12vh',
                        minHeight: 'calc(100vh - 50px)',
                    }}
                >
                    {menuService.cart &&
                        menuService.cart.map((cartItem) => (
                            <CartItemHandler
                                key={cartItem.item.id}
                                cartItem={cartItem}
                            />
                        ))}
                    {menuService.cart.length === 0 && (
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: 'calc(88vh - 50px)' }}
                        >
                            <img
                                style={{ width: '60%' }}
                                src={emptyCart}
                                alt="Carrello vuoto"
                            />
                            <Typography
                                fontWeight="bold"
                                variant="h6"
                                width="70%"
                                sx={{ textAlign: 'center', marginTop: '30px' }}
                            >
                                Il tuo carrello è vuoto al momento!
                            </Typography>
                        </Stack>
                    )}
                </Container>
                <FixedPriceAndButtonBottomBar
                    price={menuService.getTotalCartPrice() / 100}
                    onButtonClick={async () => {
                        const currentMenu = (await db.getTenantObject()).data()[modeToMenuKey[mode]]
                        if(menuService.menu.id === currentMenu) {
                            setOpenOrderpage(true);
                        } else {
                            setContent(<Typography>
                                Il menù dell'applicazione è stato cambiato. Per poter effettuare 
                                un ordine, ricarica la pagina!
                            </Typography>);
                        }
                    }}
                    priceHeaderText="Totale parziale"
                    buttonText="Conferma"
                    height="12vh"
                    zIndex="15"
                    showButton={menuService.getTotalCartPrice() !== 0 && (tenantService.tenantDetails.license === "standard" || mode === "remote")}
                    isFixed={true}
                />
            </Box>
            <ScrollingFixedContainer
                isOpen={isOpenOrderPage}
                onClose={() => {
                    setOpenOrderpage(false);
                }}
            >
                {mode === 'table' ? (
                    <QRCodeViewer
                        onClose={() => {
                            setOpenOrderpage(false);
                        }}
                    />
                ) : tenantService.tenantDetails.waNumber ? (<ContactScrollingPage
                        onClose={() => {
                            setOpenOrderpage(false);
                        }}
                    />)
                    : (<ContactScrollingPageOnlyCalls
                        onClose={() => {
                            setOpenOrderpage(false);
                        }}
                    />)
                    }
            </ScrollingFixedContainer>
        </>
    ) : (
        <LoadPage />
    );
};
