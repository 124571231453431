import { _BaseModel } from './_baseModel';
export class TenantModel extends _BaseModel {
    static collectionName = 'tenants';

    constructor({
        borderRadius,
        coverImage,
        font,
        phoneNumber,
        palette,
        waNumber,
        callNumber,
        externalMenuID,
        internalMenuID,
        remoteMenuID,
        license,
        hasDelivery
    }) {
        super();
        this.borderRadius = borderRadius;
        this.coverImage = coverImage;
        this.font = font;
        this.phoneNumber = phoneNumber;
        this.callNumber = callNumber;
        this.waNumber = waNumber;
        this.palette = palette;
        this.externalMenuID = externalMenuID;
        this.internalMenuID = internalMenuID;
        this.remoteMenuID = remoteMenuID;
        this.license = license;
        this.hasDelivery = hasDelivery;
    }

    static fromSnapshot(snapshot) {
        return new TenantModel({
            borderRadius: snapshot.border_radius,
            coverImage: snapshot.cover_image,
            font: snapshot.font.name,
            waNumber: snapshot.wa_number,
            callNumber: snapshot.call_number,
            license: snapshot.license,
            externalMenuID: snapshot.externalMenuID,
            internalMenuID: snapshot.internalMenuID,
            remoteMenuID: snapshot.remoteMenuID,
            hasDelivery: snapshot.has_delivery,
            palette: {
                alias: snapshot.palette.alias,
                primary: snapshot.palette.primary,
                secondary: snapshot.palette.secondary,
                background: snapshot.palette.background,
                grey50: snapshot.palette.grey50,
                grey150: snapshot.palette.grey150,
                grey250: snapshot.palette.grey250,
            },
        });
    }
}
