import { IconButton, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import placeholder from '../../assets/placeholder.jpg';
import { MenuContext } from '../../contexts/providers/appContexts'; 
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { multiplyBorderRadiusWithTheme } from '../../utils/theme';
import { useTheme } from '@emotion/react';

export const CartItemHandler = ({ cartItem }) => {
    const menuService = useContext(MenuContext);
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            margin="20px 0"
            alignItems="center"
            justifyContent="space-between"
        >
            <div
                className="aspect-ratio-1"
                style={{ width: '20%', flexShrink: 0 }}
            >
                <img
                    src={
                        cartItem.item.image ? cartItem.item.image : placeholder
                    }
                    style={{
                        borderRadius: multiplyBorderRadiusWithTheme(20)(theme),
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }}
                    alt={cartItem.item.name}
                />
            </div>
            <Typography
                variant="body1"
                fontWeight="bold"
                marginLeft="10px"
                marginRight="auto"
                color="grey50.main"
            >
                {cartItem.item.name}
            </Typography>
            <Stack direction="row" alignItems="center">
                <IconButton
                    sx={{ color: 'primary.main', fontSize: '2rem' }}
                    onClick={() => {
                        menuService.removeCartItem(cartItem.item, 1);
                    }}
                >
                    <RemoveCircleIcon sx={{ fontSize: '2rem' }} />
                </IconButton>
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="grey50.main"
                    width="25px"
                    textAlign="center"
                >
                    {cartItem.quantity}
                </Typography>
                <IconButton
                    sx={{
                        color: 'primary.main',
                        fontSize: '2rem',
                        '&.Mui-disabled': { color: 'secondary.main' },
                    }}
                    disabled={cartItem.quantity >= 99}
                    onClick={() => {
                        menuService.addCartItem(cartItem.item, 1);
                    }}
                >
                    <AddCircleIcon sx={{ fontSize: '2rem' }} />
                </IconButton>
            </Stack>
        </Stack>
    );
};
