import {
    Button,
    Container,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { ScrollingFixedPage } from '../../components/scrollingFixedPage';
import { TenantContext } from '../../contexts/providers/appContexts';
import { SendOrderScrollingPage } from './sendOrderScrollingPage';

export const ContactScrollingPageOnlyCalls = ({ onClose, ...rest }) => {
    const tenantService = useContext(TenantContext);
    const [insertDate, setInsertDate] = useState(false);

    return (
        <>
            {!insertDate ? (
                <ScrollingFixedPage
                    title="Contattaci"
                    onClose={onClose}
                    containerPaddingBottom="0"
                    height="auto"
                    {...rest}
                >
                    <Container>
                        <Stack>
                            <Typography
                                variant="body1"
                                textAlign="center"
                                sx={{ marginBottom: '20px' }}
                            >
                                Chiama ora al seguente numero:
                            </Typography>
                            <Typography
                                variant="h4"
                                textAlign="center"
                                sx={{
                                    color: 'primary.main',
                                    marginBottom: '20px',
                                }}
                            >
                                <Link
                                    href={`tel:${tenantService.tenantDetails.callNumber}`}
                                    sx={{
                                        color: 'primary.main',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {tenantService.tenantDetails.callNumber}
                                </Link>
                            </Typography>
                        </Stack>
                    </Container>
                </ScrollingFixedPage>
            ) : (
                <SendOrderScrollingPage onClose={onClose} {...rest} />
            )}
        </>
    );
};
