import {
    Box,
    Container,
    IconButton,
    Slide,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
    homePageContainerWidths,
    multiplyBorderRadiusWithTheme,
} from '../utils/theme';
import CancelIcon from '@mui/icons-material/Cancel';

export const ScrollingFixedContainer = ({ onClose, isOpen, children }) => {
    return (
        <Slide
            direction="up"
            in={isOpen}
            mountOnEnter
            unmountOnExit
            style={{
                position: 'fixed',
                bottom: '0',
                zIndex: 100,
            }}
        >
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                    zIndex: 15,
                    position: 'fixed',
                    margin: '0 auto',
                }}
                onClick={onClose}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        margin: '0 auto',
                    }}
                >
                    {children}
                </Box>
            </div>
        </Slide>
    );
};

export const ScrollingFixedPage = ({
    onClose,
    children,
    title,
    height = '80vh',
    fixedBottomBar,
    containerPaddingBottom,
    ...rest
}) => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    return (
        <Box
            className="no-scrollbar"
            sx={(theme) => ({
                position: 'relative',
                height: height,
                overflow: 'scroll',
                borderTopLeftRadius: multiplyBorderRadiusWithTheme(40),
                borderTopRightRadius: multiplyBorderRadiusWithTheme(40),
                width: '60%',
                backgroundColor: 'background.main',
                boxShadow: '-1px 1px 43px 6px rgba(0,0,0,0.3)',
                zIndex: 20,
                ...homePageContainerWidths(theme),
            })}
            {...rest}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Container sx={{ paddingBottom: containerPaddingBottom }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                >
                    <Typography variant="h5" fontWeight="bold">
                        {title}
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'grey150.main',
                            fontSize: '2rem',
                            alignSelf: 'start',
                        }}
                    >
                        <CancelIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                </Stack>
                {children}
            </Container>
            {fixedBottomBar && fixedBottomBar}
        </Box>
    );
};
