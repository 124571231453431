function _logN(value, base) {
    return Math.log(value) / Math.log(base);
}

function convert(item, quantity, end) {
    let result = '';

    if (_logN(item + 1, 512) > 1 || _logN(quantity + 1, 64) > 1) {
        result = convert(
            Math.floor(item / 512),
            Math.floor(quantity / 64),
            false
        );
        item = item % 512;
        quantity = quantity % 64;
    }

    const value = end ? quantity + item * 64 + 32768 : quantity + item * 64;

    return result + String.fromCharCode(value);
}

function encode(cartItemList) {
    let result = '';
    for (let e of cartItemList) {
        result += convert(parseInt(e[0]), parseInt(e[1]), true);
    }
    return result;
}

export { encode };
