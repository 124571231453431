export class ItemModel {
    static collectionName = "items";

    constructor({ id, active, alias, allergens, description, image, ingredients, name, price }) {
        this.id = id;
        this.active = active;
        this.alias = alias;
        this.allergens = allergens;
        this.description = description;
        this.image = image;
        this.ingredients = ingredients;
        this.name = name;
        this.price = price;
    }

    static fromSnapshot(snapshot) {
        return new ItemModel({...snapshot});
    }
}