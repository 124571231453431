import { Button, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedBottomBar } from '../../components/fixedBottomBar';
import { MenuContext } from '../../contexts/providers/appContexts';
import QRCode from 'qrcode.react';
import { ScrollingFixedPage } from '../../components/scrollingFixedPage';

export const QRCodeViewer = ({ onClose, ...rest }) => {
    const menuService = useContext(MenuContext);
    const navigate = useNavigate();
    const [QRCodeString, _] = useState(menuService.generateCartQRCodeString());

    return (
        <ScrollingFixedPage
            title="Ordine generato"
            onClose={onClose}
            containerPaddingBottom="12vh"
            height="85vh"
            fixedBottomBar={
                <FixedBottomBar height="12vh">
                    <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            onClick={() => {
                                menuService.clearCart();
                                navigate(-1);
                            }}
                            variant="white-btn"
                        >
                            Nuovo ordine
                        </Button>
                    </Stack>
                </FixedBottomBar>
            }
            {...rest}
        >
            <Box
                sx={{
                    padding: '20px',
                    backgroundColor: '#fffffe',
                    borderRadius: '20px',
                    width: '285px',
                    margin: '10px auto',
                }}
            >
                <Box className="aspect-ratio-1">
                    <QRCode
                        style={{ width: '100%', height: '100%' }}
                        value={QRCodeString}
                        renderAs="svg"
                    />
                </Box>
            </Box>
            <Container>
                <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ margin: '20px 0' }}
                >
                    Mostra il QR code ad un cameriere per inviare il tuo ordine
                    in cucina. Dopo la scansione del codice svuota il tuo
                    carrello per non richiedere nuovamente gli stessi prodotti
                    successivamente.
                </Typography>
            </Container>
        </ScrollingFixedPage>
    );
};
