export class CategoryModel {
    constructor({ name, items }) {
        this.name = name;
        this.items = items;
    }

    static fromSnapshotAndItems(snapshot, items) {
        return new CategoryModel({
            name: snapshot.name,
            items: CategoryModel._getItems(snapshot.itemsID, items)
        })
    }

    //TODO migliorare efficienza
    static _getItems(itemsID, items){
        return itemsID.reduce((p,c) => {
            let item = items.find((e) => e.id === c);
            if(item) p.push(item);
            return p;
        }, []);
    }
}