import { useFirebase } from "./contexts/services/firebase";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./appRoutes";
import { useDialog } from "./contexts/services/dialogService";
import { DialogContext } from "./contexts/providers/appContexts";

function App() {
  const firebase = useFirebase();
  const dialogService = useDialog();
  
  firebase.init();

  return (
      <BrowserRouter>
        <DialogContext.Provider value={dialogService}>
          <AppRoutes />
        </DialogContext.Provider>
      </BrowserRouter>
  );
}

export default App;
