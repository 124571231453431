import { useRef, useState, useEffect, useContext } from 'react';
import { useFirestore } from './firebase';
import { ItemModel } from '../models/itemModel';
import { MenuModel } from '../models/menuModel';
import { CartItem } from '../models/cartModel';
import { encode } from '../../utils/compressQR';
import { v4 as uuidv4 } from 'uuid';
import { DialogContext } from '../providers/appContexts';
import { Typography } from '@mui/material';

export const useMenu = () => {
    const [menu, setMenu] = useState(null);
    const [cart, setCart] = useState([]);
    const itemsCounterRef = useRef({});
    const db = useFirestore();
    const { setContent } = useContext(DialogContext);

    useEffect(() => {
        if (menu !== null) {
            const menuItems = menu.getItems();
            const itemsToDelete = cart.filter(
                (cartItem) =>
                    menuItems.findIndex(
                        (menuItem) => menuItem.id === cartItem.item.id
                    ) === -1
            );

            if (itemsToDelete.length > 0) {
                setCart((prevCart) => {
                    return prevCart.filter(
                        (cartItem) =>
                            menuItems.findIndex(
                                (menuItem) => menuItem.id === cartItem.item.id
                            ) !== -1
                    );
                });
                setContent(<Typography component={"div"}>
                    I seguenti prodotti sono stati eliminati dal tuo carrello, in quanto non più disponibili:
                    <ul>
                        {itemsToDelete.map(cartItem => <li key={cartItem.item.id}>{cartItem.item.name}</li>)}
                    </ul>
                </Typography>);
            }
        }
    }, [menu])

    const incrementItemCounter = (itemId) => {
        if (
            itemsCounterRef.current[itemId].quantity +
                itemsCounterRef.current[itemId].addedQuantity <
            100
        ) {
            itemsCounterRef.current[itemId].quantity++;
            return true;
        }
        return false;
    };

    const resetItemCounter = (itemId) => {
        const cartItem = cart.find((el) => el.item.id === itemId);
        itemsCounterRef.current[itemId] = {
            toastId: uuidv4(),
            quantity: 1,
            addedQuantity: cartItem ? cartItem.quantity : 0,
        };
    };

    const updateMenu = async (menuSnapshot) => {
        try {
            const itemsSnapshot = await getItemsSnapshot();
            setMenu(
                MenuModel.fromSnapshotAndItems(menuSnapshot, itemsSnapshot)
            );
        } catch (e) {
            setMenu(() => {
                throw e;
            });
        }
    };

    const initMenuSnapshotListener = (menuId) => {
        db.getSubscriptionFromTenantCollectionObject(
            'menu',
            menuId,
            async (snapshot) => {
                await updateMenu(snapshot.data());
            }
        );
    };

    const getItemsSnapshot = async () => {
        const itemsSnapshot = (
            await db.listTenantObjects(ItemModel.collectionName)
        ).docs[0].data();
        return itemsSnapshot;
    };

    const addCartItem = (item, quantity) => {
        const cartItem = cart.find((el) => el.item.id === item.id);

        if (!cartItem) {
            cart.push(new CartItem({ item, quantity }));
        } else {
            if (cartItem.quantity < 99)
                cartItem.quantity =
                    cartItem.quantity + quantity > 99
                        ? 99
                        : cartItem.quantity + quantity;
        }

        setCart(() => [...cart]);
    };

    const removeCartItem = (item, quantity) => {
        const cartItemIndex = cart.findIndex((el) => el.item.id === item.id);

        if (cartItemIndex !== -1) {
            cart[cartItemIndex].quantity -= quantity;
            if (cart[cartItemIndex].quantity < 0) {
                cart.splice(cartItemIndex, 1);
            }
        }

        setCart(() => [...cart]);
    };

    const clearCart = () => {
        setCart([]);
    };

    const getTotalCartItems = () =>
        cart.reduce((acc, curr) => acc + curr.quantity, 0);
    const getTotalCartPrice = () =>
        cart.reduce((acc, curr) => acc + curr.quantity * curr.item.price, 0);

    const generateCartQRCodeString = () =>
        encode(cart.map((el) => [el.item.id, el.quantity]));

    return {
        menu,
        setMenu,
        addCartItem,
        removeCartItem,
        getTotalCartItems,
        getTotalCartPrice,
        cart,
        clearCart,
        generateCartQRCodeString,
        incrementItemCounter,
        resetItemCounter,
        itemsCounterRef,
        initMenuSnapshotListener,
    };
};
