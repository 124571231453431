import { Button, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { multiplyBorderRadiusWithTheme } from '../utils/theme';

export const FixedBottomBar = ({
    height,
    children,
    zIndex,
    fullWidth = false,
}) => {
    return (
        <Box
            position={'fixed'}
            height={height}
            zIndex={zIndex ? zIndex : '150'}
            width="100%"
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                bottom: 0,
                borderTopLeftRadius: multiplyBorderRadiusWithTheme(40),
                borderTopRightRadius: multiplyBorderRadiusWithTheme(40),
                width: fullWidth ? '100%' : 'inherit',
            }}
        >
            {children}
        </Box>
    );
};

export const FixedPriceAndButtonBottomBar = ({
    height,
    price,
    buttonText,
    priceHeaderText,
    onButtonClick,
    zIndex,
    showButton = true,
    fullWidth = false,
}) => {
    return (
        <FixedBottomBar height={height} zIndex={zIndex} fullWidth={fullWidth}>
            <Container
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Stack>
                    <Typography
                        variant="body2"
                        color="grey250.main"
                        sx={{ opacity: 0.6 }}
                    >
                        {priceHeaderText}
                    </Typography>
                    <Typography variant="h6" color="grey250.main">
                        {price.toFixed(2)} €
                    </Typography>
                </Stack>
                {showButton && (
                    <Button
                        onClick={onButtonClick}
                        variant="white-btn"
                        disableElevation
                    >
                        {buttonText}
                    </Button>
                )}
            </Container>
        </FixedBottomBar>
    );
};
