import { _BaseModel } from "./_baseModel";
import { CategoryModel } from "./categoryModel";
import { ItemModel } from "./itemModel";
export class MenuModel extends _BaseModel {
    static collectionName = 'menu'

    constructor({ id, categories, name }) {
        super();
        this.categories = categories;
        this.name = name;
        this.id = id;
    }

    static fromSnapshotAndItems(snapshot, itemsSnapshot) {
        const items = itemsSnapshot.items.map(el => ItemModel.fromSnapshot(el));
        return new MenuModel({
            id: snapshot.id,
            name: snapshot.name,
            categories: snapshot.category.map(el => CategoryModel.fromSnapshotAndItems(el, items))
        })
    }

    getItems() {
        return this.categories.map(category => category.items).flat()
    }
}