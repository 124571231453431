import { useContext } from 'react';
import { toast } from 'react-toastify';
import { MenuContext } from '../contexts/providers/appContexts';

export const useCartToast = (item) => {
    const menuService = useContext(MenuContext);

    const showOrUpdateCartToast = () => {
        if (
            !menuService.itemsCounterRef.current[item.id] ||
            !toast.isActive(
                menuService.itemsCounterRef.current[item.id].toastId
            )
        ) {
            menuService.resetItemCounter(item.id);
            if (menuService.itemsCounterRef.current[item.id].addedQuantity < 99)
                toast(
                    `Hai aggiunto ${
                        menuService.itemsCounterRef.current[item.id].quantity
                    } ${item.name} al carrello`,
                    {
                        autoClose: 2500,
                        toastId:
                            menuService.itemsCounterRef.current[item.id]
                                .toastId,
                    }
                );
        } else {
            if (menuService.incrementItemCounter(item.id)) {
                toast.update(
                    menuService.itemsCounterRef.current[item.id].toastId,
                    {
                        render: `Hai aggiunto ${
                            menuService.itemsCounterRef.current[item.id]
                                .quantity
                        } ${item.name} al carrello`,
                        autoClose: 2500,
                    }
                );
            }
        }
    };

    return { showOrUpdateCartToast };
};
