import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import { ItemGridTile } from './itemGridTile';

export const CategoryPanel = ({ category, onSelectItem }) => {
    const itemsWithImage = category.items.filter((item) => !!item.image);
    const itemsWithoutImage = category.items.filter((item) => !item.image);
    // 100px: appbar + tabbar + 2px
    return (
        <Box sx={{ backgroundColor: 'background.main' }}>
            <Container
                sx={{
                    minHeight: 'calc(100vh - 98px)',
                    backgroundColor: 'background.main',
                    paddingTop: '10px',
                    paddingBottom: '20px',
                }}
            >
                <Grid container spacing={2}>
                    {itemsWithImage.map((item) => (
                        <ItemGridTile
                            onClick={() => {
                                onSelectItem(item);
                            }}
                            item={item}
                            key={item.name}
                        />
                    ))}
                    {itemsWithImage.length % 2 !== 0 && <Grid item xs={6}></Grid>}
                    {itemsWithoutImage.map((item) => (
                        <ItemGridTile
                            onClick={() => {
                                onSelectItem(item);
                            }}
                            item={item}
                            key={item.name}
                        />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};
