import { Card, CardContent, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"

export const ErrorPage = () => {
    return <Box sx={{height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", background: "gray"}}>
        <Card sx={{marginLeft: "20px", marginRight: "20px", maxWidth: "500px"}}>
            <CardContent>
                <Typography variant="h3">Errore</Typography>
                <Typography variant="h6" marginTop="20px" marginBottom="20px">Si è verificato un'errore durante il caricamento del menù digitale.</Typography>
                <Typography variant="h6">Assicurati di aver digitato correttamente il link o, se sei un ristoratore, di aver inizializzato il menù!</Typography>
            </CardContent>
        </Card>
    </Box>
}