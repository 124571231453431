import React, { useContext } from 'react';
import { Button, Grid, Stack, Typography, Paper } from '@mui/material';
import placeholder from '../../assets/placeholder.jpg';
import { MenuContext } from '../../contexts/providers/appContexts';
import { useTheme } from '@emotion/react';
import { multiplyBorderRadiusWithTheme } from '../../utils/theme';
import AddIcon from '@mui/icons-material/Add';
import { useCartToast } from '../../components/toast';

export const ItemGridTile = ({ item, onClick }) => {
    const menuService = useContext(MenuContext);
    const theme = useTheme();
    const cartToast = useCartToast(item);

    return (
        <Grid
            onClick={() => {
                onClick();
            }}
            item
            xs={6}
            display="flex"
            flexDirection="column"
            justifyContent={'stretch'}
            sx={{
                cursor: 'pointer',
                WebkitTapHighlightColor: 'transparent',
            }}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    padding: "10px",
                    bgcolor: "grey250.main",
                    borderRadius: multiplyBorderRadiusWithTheme(40)(theme)
                }}
                elevation={0}
            >
                {item.image && (
                    <div className="aspect-ratio-1">
                        <img
                            src={item.image ? item.image : placeholder}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius:
                                    multiplyBorderRadiusWithTheme(40)(theme),
                            }}
                            alt={item.name}
                            loading="lazy"
                        />
                    </div>
                )}
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ margin: item.image ? '10px 0 auto 0' : '0 0 auto 0' }}
                    color="grey50.main"
                >
                    {item.name}
                </Typography>
                <Typography
                    variant="body1"
                    color="grey150.main"
                    align="right"
                    sx={{ margin: '6px 0' }}
                >
                    € {(item.price / 100).toFixed(2)}
                </Typography>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={(e) => {
                        e.stopPropagation();
                        menuService.addCartItem(item, 1);
                        cartToast.showOrUpdateCartToast();
                    }}
                    sx={{ paddingLeft: '10px', marginTop: '0', borderRadius: multiplyBorderRadiusWithTheme(40)(theme) }}
                >
                    <Stack direction="row" alignItems="start">
                        <AddIcon />
                        AGGIUNGI
                    </Stack>
                </Button>
            </Paper>
        </Grid>
    );
};
