import { Chip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { FixedPriceAndButtonBottomBar } from '../../components/fixedBottomBar';
import { ScrollingFixedPage } from '../../components/scrollingFixedPage';
import { useCartToast } from '../../components/toast';
import { MenuContext } from '../../contexts/providers/appContexts';

export const ItemDetails = ({ item, onClose, ...rest }) => {
    const menuService = useContext(MenuContext);
    const cartToast = useCartToast(item);

    return (
        <ScrollingFixedPage
            title={item.name}
            containerPaddingBottom="12vh"
            onClose={onClose}
            fixedBottomBar={
                <FixedPriceAndButtonBottomBar
                    height="12vh"
                    priceHeaderText="Prezzo"
                    buttonText="Aggiungi"
                    price={item.price / 100}
                    onButtonClick={() => {
                        menuService.addCartItem(item, 1);
                        cartToast.showOrUpdateCartToast();
                    }}
                    {...rest}
                />
            }
        >
            <Typography
                variant="body1"
                sx={{ margin: '15px 0' }}
                color="grey150.main"
            >
                {item.description}
            </Typography>
            {item.ingredients.length > 0 && (
                <Typography variant="h6" marginBottom="10px" fontWeight="bold">
                    Ingredienti:
                </Typography>
            )}
            <Box>
                {item.ingredients.map((ingredient) => (
                    <Chip
                        variant="outlined"
                        key={ingredient}
                        label={ingredient}
                        sx={{ marginRight: '10px', marginBottom: '10px' }}
                    />
                ))}
            </Box>
            {item.allergens.length > 0 && (
                <Typography
                    variant="h6"
                    marginTop="10px"
                    marginBottom="10px"
                    fontWeight="bold"
                >
                    Allergeni:
                </Typography>
            )}
            <Box>
                {item.allergens.map((allergen) => (
                    <Chip
                        variant="outlined"
                        key={allergen}
                        label={allergen.split("_").join(" ")}
                        sx={{ marginRight: '10px', marginBottom: '10px' }}
                    />
                ))}
            </Box>
        </ScrollingFixedPage>
    );
};
