import React, { useEffect, useState, useRef, useContext } from 'react';
import { createTheme } from '@mui/material/styles';
import { _baseTheme } from '../../utils/theme';
import { useTenant } from '../services/tenantService';
import { ThemeProvider } from '@mui/material';
import { LoadPage } from '../../components/LoadPage';
import { Outlet, useParams } from 'react-router-dom';
import { useMenu } from '../services/menuService';
import { TenantContext, MenuContext, DialogContext } from './appContexts';
import ConfirmDialog from '../../pages/components/confirmDialog';

export const modeToMenuKey = {
    table: 'externalMenuID',
    remote: 'remoteMenuID',
};

const AppProvider = () => {
    const tenantService = useTenant();
    const menuService = useMenu();

    const dialogService = useContext(DialogContext);

    const [themeDefinition, setThemeDefinition] = useState(null);

    const unsubscribeMenuListener = useRef(null);

    const { mode } = useParams();

    const initTenantDetailsAndMenu = async () => {
        let tenantData = await tenantService.getTenantDetails();
        const _theme = _baseTheme;
        _theme.borderRadius = tenantData.borderRadius;
        const paletteKeys = Object.keys(_theme.palette);
        _theme.palette = Object.entries(tenantData.palette).reduce(
            (pre, curr) => {
                const [key, value] = curr;
                if (paletteKeys.includes(key)) {
                    pre = {
                        ...pre,
                        [key]: { main: '#' + value },
                    };
                }
                return pre;
            },
            {}
        );
        _theme.typography = {
            fontFamily: [
                `'${tenantData.font}'`,
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
        };

        document.querySelectorAll('meta')[3].content =
            _theme.palette.background.main;
        document.body.style.backgroundColor = _theme.palette.background.main;

        document.documentElement.style.setProperty(
            '--toastify-color-progress-light',
            _theme.palette.primary.main
        );
        document.documentElement.style.setProperty(
            '--toastify-text-color-light',
            _theme.palette.grey150.main
        );

        setThemeDefinition(() => _theme);

        //await menuService.initMenu(tenantData[modeToMenuKey[mode]]);
        unsubscribeMenuListener.current = menuService.initMenuSnapshotListener(
            tenantData[modeToMenuKey[mode]]
        );
    };

    useEffect(() => {
        initTenantDetailsAndMenu();

        return () => {
            if (unsubscribeMenuListener.current) {
                unsubscribeMenuListener.current();
            }
        };
    }, []);

    //TODO: darktheme per icona calendario
    /* '::-webkit-calendar-picker-indicator': {
                                      filter: 'invert(1)',
                                  }, */

    const theme = themeDefinition
        ? createTheme(themeDefinition, {
              typography: {
                  h1: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  h2: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  h3: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  h4: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  h5: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  h6: {
                      color: themeDefinition.palette.grey50.main,
                  },
                  body1: {
                      color: themeDefinition.palette.grey150.main,
                  },
              },
              components: {
                  MuiButton: {
                      variants: [
                          {
                              props: { variant: 'white-btn' },
                              style: {
                                  backgroundColor:
                                      themeDefinition.palette.grey250.main,
                                  color: themeDefinition.palette.primary.main,
                                  borderRadius: '20px',
                                  '&:hover': {
                                      backgroundColor:
                                          themeDefinition.palette.grey250.main,
                                  },
                              },
                          },
                          {
                              props: { variant: 'contained-primary' },
                              style: {
                                  backgroundColor:
                                      themeDefinition.palette.primary.main,
                                  color: themeDefinition.palette.grey250.main,
                                  '&:hover': {
                                      backgroundColor:
                                          themeDefinition.palette.primary.main,
                                  },
                              },
                          },
                      ],
                      styleOverrides: {
                          contained: {
                              backgroundColor:
                                  themeDefinition.palette.secondary.main,
                              color: themeDefinition.palette.primary.main,
                              '&:hover': {
                                  backgroundColor:
                                      themeDefinition.palette.secondary.main,
                              },
                          },
                      },
                  },
                  MuiChip: {
                      styleOverrides: {
                          outlined: {
                              color: themeDefinition.palette.grey50.main,
                              fontSize: '1em',
                              borderColor: themeDefinition.palette.grey50.main,
                              backgroundColor:
                                  themeDefinition.palette.grey250.main,
                          },
                      },
                  },
                  MuiInputBase: {
                      styleOverrides: {
                          root: {
                              '&.MuiOutlinedInput-root': {
                                  color: themeDefinition.palette.grey50.main,
                                  '& fieldset': {
                                      borderColor:
                                          themeDefinition.palette.grey150.main,
                                  },
                                  '&:hover fieldset': {
                                      borderColor:
                                          themeDefinition.palette.grey150.main,
                                  },
                              },
                          },
                      },
                  },
              },
          })
        : null;

    return (
        <TenantContext.Provider value={tenantService}>
            {themeDefinition ? (
                <ThemeProvider theme={theme}>
                        <MenuContext.Provider value={menuService}>
                            <ConfirmDialog
                                open={dialogService.open}
                                handleClose={dialogService.closeDialog}
                                title="Avviso"
                            >
                                {dialogService.content}
                            </ConfirmDialog>
                            <Outlet />
                        </MenuContext.Provider>
                </ThemeProvider>
            ) : (
                <LoadPage />
            )}
        </TenantContext.Provider>
    );
};

export default AppProvider;
